body {
  padding-top: 3rem;
  padding-bottom: 3rem;
  color: #5a5a5a;
}

.carousel {
  margin-bottom: 4rem;
}

.carousel-caption {
  bottom: 3rem;
  z-index: 10;
}

.carousel-item {
  height: 10rem;
  background-color: #777777;
}

.carousel-item > img {
  position: absolute;
  top: 0;
  left: 0;
  min-width: 100%;
  height: 32rem;
}

.navbar-brand {
  padding-left: 20px;
}

ul.header li {
  display: inline;
  list-style-type: none;
  margin: 0;
}

ul.header li a {
  /* color: yellow; */
  font-weight: bold;
  text-decoration: none;
  padding: 20px;
  display: inline-block;
}
